import React from "react"
import "./index.scss"

const openTab = e => {
  const allTabs = document.querySelectorAll(
    ".cashnowatm--faq__content-item-content"
  )
  const allBtns = document.querySelectorAll(
    ".cashnowatm--faq__content-item-toggle"
  )
  if (
    e.target.classList.contains("cashnowatm--faq__content-item-toggle--show")
  ) {
    allTabs.forEach(tab => {
      tab.dataset.item === e.target.dataset.item &&
        tab.classList.remove("cashnowatm--faq__content-item-content--show")
      e.target.classList.remove("cashnowatm--faq__content-item-toggle--show")
    })
    return
  }
  if (!e.target.dataset.item) {
    return
  }
  const numberTab = e.target.dataset.item
  allTabs.forEach(tab => {
    tab.dataset.item === numberTab &&
      tab.classList.add("cashnowatm--faq__content-item-content--show")
    e.target.classList.add("cashnowatm--faq__content-item-toggle--show")
  })
}

const items = [
  // {
  //     title: 'Що це за продукт «Кредит готівкою»?',
  //     content: `<p>«Кредит готівкою - це продукт, який надається на споживчі потреби в межах від 1 000,00 грн. до 500 000,00 грн. на поточний рахунок із використанням платіжної картки</p>`
  // },
  // {
  //     title: 'Чи обов’язково надавати Позичальнику оригінал документу про підтвердження доходів?',
  //     content: `<p>Документи, що підтверджують доходи фізичної особи надаються у випадку якщо сума кредиту більше 50 тис. грн.</p>`
  // },
  // {
  //     title: 'Послуга страхування обов’язкова при оформленні кредиту?',
  //     content: `<p>Послуга страхування відсутня.</p>`
  // },
  // {
  //     title: 'Чи можливе дострокове погашення по продукту «Кредит готівкою»?',
  //     content: `<p>Так. Дострокове погашення можливе без додаткових комісій.</p>`
  // },
  // {
  //     title: 'Як оплачувати отриманий кредит?',
  //     content: `<p>Ви можете вибрати найбільш зручний спосіб погашення кредиту.</p>
  //         <p>- Через касу банку;</p>
  //         <p>- Через термінали самообслуговування;</p>
  //         <p>- Через мобільний додаток TAS2U.</p>
  //     `
  // },
  {
    title: "Умови продукту",
    content: `
            <p>Сума кредиту: від 1 000 до 500 000 грн.</p>
            <p>Строк кредиту: від 6 до 60 міс.</p>
            <p>Процентна ставка за кредитом: 0,01% річних (фіксована).</p>
            <p>Щомісячна комісія: 2,5% (нараховується на початкову суму кредиту).</p>
            <p>Реальна річна процентна ставка: від 54,42 до 65,05% річних (залежить від умов кредитування).</p>
            <div class="separator"></div>
            <ul>
                <li>Без разової комісії за надання кредиту;</li>
                <li>Без комісії за дострокове погашення;</li>
                <li>Без застави та поручителів;</li>
                <li>Без послуги страхування життя;</li>
                <li>Швидке прийняття рішення;</li>
                <li>Простий спосіб оформлення.</li>
            </ul>

            <div class="bordered">
                <p>Згідно ст.15 п.1 ЗУ «Про споживче кредитування» споживач має право протягом 14 календарних днів з дня укладання договору кредитування відмовитися від договору без пояснення причин, у тому числі у разі отримання ним грошових коштів.</p>
                <p>У разі відмови від договору про споживчий кредит, споживач повертає банку грошові кошти, одержані за цим договором та сплачує відсотки за період з дня одержання коштів до дня їх повернення за процентною ставкою, встановленою договором, та вчиняє інші дії, передбачені Законом України «Про споживче кредитування» або договором про споживчий кредит.</p>
                <p>Для відмови від договору про споживчий кредит споживач має звернутися до будь-якого відділення АТ «ТАСКОМБАНК» та подати відповідну заяву про відмову в письмовому вигляді.</p>
            </div>
            <p><a href="https://tascombank.ua/files/23.04.2024_Poperedzhennia_ta_mozhlyvi_naslidky_dlia_korystuvacha_produktom_%C2%ABZruchna_gotivka_Maksymum%C2%BB.pdf" target="_blank">Попередження та можливі наслідки для користувача продуктом «Зручна готівка Максимум»</a></p>
            <p><a href="https://tascombank.ua/files/Istotni_kharakterystyky_poslugy_z_nadannia_spozhyvchogo_kredytu_Zruchna_gotivka_MAKSYMUM_18.03.2025.pdf" target="_blank">Істотні характеристики послуги з надання споживчого кредиту «Зручна готівка Максимум»</a></p>
        `,
  },
  {
    title: "Калькулятор",
    content: `<p>Розрахувати кредит можна на офіційному сайті Банку у підрозділі «Калькулятор» <a href="https://tascombank.ua/you/credits/zruchna_gotivka_maksymum" target="_blank">за посиланням</a></p>`,
  },
  {
    title: "Документи продукту",
    content: `
            <p><a href="https://tascombank.ua/files/buro-kredit-story.pdf" target="_blank">Інформація щодо бюро кредитних історій</a></p>
            <p><a href="https://tascombank.ua/files/Sposoby_pogashennia_kredytu_AT_TASKOMBANK_04.2024.pdf" target="_blank">Способи погашення кредитів АТ ТАСКОМБАНК</a></p>
            <p><a href="https://tascombank.ua/files/Pasport_spozhyvchogo_kredytu_za_produktom_Zruchna_gotivka_Maksymum-01-02.pdf" target="_blank">Паспорт споживчого кредиту за продуктом Зручна готівка Максимум</a></p>
            <p><a href="https://tascombank.ua/files/Publichna_propozytsiya_TASCOMBANK_DKBO.pdf" target="_blank">Публічна пропозиція АТ «ТАСКОМБАНК» на укладання Договору про комплексне банківське обслуговування фізичних осіб</a></p>
            <p><a href="https://tascombank.ua/files/TF_ZD_PRO_NADANNYa_SPOZhYVCh_KREDYTU_NA_VLASNI_POTREBY_v1_28032024.pdf" target="_blank">Типова форма Заяви - договору про надання споживчого кредиту на власні потреби/рефінансування кредитної заборгованості (кредит Зручна Готівка Максимум)</a></p>
            <!--<p><a href="https://tascombank.ua/files/Istotni_kharakterystyky_poslugy_z_nadannia_spozhyvchogo_kredytu_Zruchna_gotivka_MAKSYMUM_20092023.pdf" target="_blank">Істотні характеристики послуги з надання споживчого кредиту «Зручна готівка МАКСИМУМ»</a></p>
            <p><a href="https://tascombank.ua/files/Poperedzhennia_ta_mozhlyvi_naslidky_dlia_korystuvacha_produktom_%C2%ABZruchna_gotivka_Maksymsdsdum%C2%BB-2604.pdf" target="_blank">Попередження та можливі наслідки для користувача продуктом «Зручна готівка Максимум»</a></p>-->
        `,
  },
]

const FAQ = () => {
  return (
    <div className="cashnowatm--faq">
      <div className="container">
        <div className="cashnow__header">Інформація про продукт</div>
        <div className="cashnowatm--faq__content">
          {items &&
            items.map((item, index) => {
              return (
                <div className="cashnowatm--faq__content-item" key={index}>
                  <div className="cashnowatm--faq__content-item-title">
                    {item.title}
                    <div
                      className={`cashnowatm--faq__content-item-toggle ${
                        index == 0
                          ? "cashnowatm--faq__content-item-toggle--show"
                          : ""
                      }`}
                      data-item={`${index}`}
                      onClick={openTab}
                    >
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <div
                    className={`cashnowatm--faq__content-item-content ${
                      index == 0
                        ? "cashnowatm--faq__content-item-content--show"
                        : ""
                    }`}
                    data-item={`${index}`}
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  ></div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default FAQ
